import React from "react"

import Layout from "components/Layout/de"
import Product from "components/Layout/Product"
import productImg from "./product.jpg"
import productIcon from "./LogisticsControl.png"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Automatisieren Sie Ihre Logistik-Prozesse durch das effiziente
        Leercontainer-Tracking-System von MoonVision – durch visuelle
        Objekterkennung an Fahrzeugen (Staplern) zur Lückenlosen
        Bilddokumentation vom Wareneingang bis hin zur Abfertigung. MoonVision
        verfügt über höchste Erkennungsgenauigkeit dank umfassende Erfahrung.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Echtzeit Bestandslagerhaltung von leeren Containern</li>
        <li>Automatische Zuordnung der Container zu Lagerbereichen</li>
        <li>
          Unterscheidung verschiedener Behältertypen durch künstliche
          Intelligenz
        </li>
        <li>Visuelle Darstellung</li>
        <li>Datenübertragung an Folgesysteme</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="LogisticsControl"
        title="Leergut Paletten-Erkennung in Echtzeit"
        subtitle="Digitale Leergut-Paletten-Erkennung in Logistik-Prozessen"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated component testing",
          "Consistently high quality",
          "Shortened lead time",
          "Find out now",
        ]}
      >
        <React.Fragment>
          <p>
            MoonVision LogisticsControl wurde speziell für die Identifizierung
            von Leergütern konzipiert. Dabei wird durch die automatische
            Erstellung von Snapshots, Clustering und verschiedenen Methoden des
            Object Mining schnell ein Überblick über alle möglichen Container in
            Ihren Logistiklagern gewonnen.
          </p>
          <p>
            Bei der Übernahme von Leergütern durch den Staplerfahrer, werden die
            Leergüter automatisch durch eine am Stapler montierte Kamera
            identifiziert, damit diese in weiterer Folge zum richtigen dafür
            vorgesehenen Ablageort transportiert werden können. Die Erkennung
            der Leergüter nach Art und Menge kann sowohl bei der Übernahme in
            der Produktions- / Fertigungsstraße bzw. bei Anlieferungen beim LKW
            erfolgen.
          </p>
          <p>
            Die Leergüter können je nach Anwendungsfall sowohl im neuen
            (unbeschädigten) als auch im gebrauchten Zustand sein und
            Abnutzungserscheinungen aufweisen.
          </p>
          <h3>Produktübersicht</h3>
          <ul>
            <li>Automatisierte Container Erkennung</li>
            <li>reduzierte Übernahmezeiten</li>
            <li>Verkürzte Durchlaufzeiten</li>
          </ul>
          <h3>Überall Einsetzbar</h3>
          <p>
            MoonVision LogisticsControl kann alle bestehenden Kamerasysteme als
            Datenquellen nutzen – die den Genicam Standard erfüllen. Gerne
            beraten wir Sie auch bei der Wahl des richtigen Systems. Die
            Überprüfung kann in Echtzeit über unsere Online-Plattform erfolgen.
            Alternativ dazu können wir das System auch vor Ort installieren. In
            beiden Fällen erhalten Sie Zugriff zur MoonVision Toolbox und können
            damit jederzeit Anpassungen zB zur Parameteradaptierung und
            Personalisierung durchführen.
          </p>
          <h3>Einfach in der Anwendung</h3>
          <p>
            Aufgrund des Softwaredesigns ist die Optimierung und Implementierung
            von MoonVision LogisticsControl mit geringem Aufwand vor Ort bei
            Ihnen realisierbar. Bereits nach der Überprüfung weniger Bild/Video
            Daten herrscht Gewissheit über die Qualität, weitere Bilddaten
            können auch zu einem späteren Zeitpunkt ergänzt werden. Alle Daten
            werden zentral gesammelt, gespeichert und sind jederzeit für Sie
            abrufbar.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
